/* eslint-disable no-alert, max-len */
import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import ElementAlert from 'src/components/ElementAlert/ElementAlert';
import { useForm, useTheme } from 'src/hooks';

function WebFormContactUs() {
  const t = useTheme();
  const formElements = [
    { name: 'name', label: t.form.name, type: 'text', required: true },
    { name: 'email', label: 'E-mail', type: 'email', required: true },
    { name: 'message', label: t.form.message, type: 'text', required: true, as: 'textarea', rows: 5 },
    {
      name: 'privacy_policy',
      type: 'checkbox',
      custom: true,
      required: true,
      label: (
        <span className="text-size-sm text-white">
          <span className="mr-1">{t.form.gdprConsent}</span>
          <button
            type="button"
            onClick={() => window.open(`/${t.navigation.gdpr.path}`, '_blank')}
            className="text-secondary font-weight-semi-bold border-transparent bg-transparent shadow-none">
            {t.navigation.gdpr.label}
          </button>
        </span>
      ),
    },
  ];
  const initialValues = { name: '', email: '', message: '', privacy_policy: false, webform_id: 'contact_us' };
  const { formik, isLoading, isSuccess, isError } = useForm({ initialValues });

  return (
    <Container fluid className="px-0 py-6">
      <Container>
        <Row>
          <Col xs={12} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
            <Form onSubmit={formik.handleSubmit}>
              {formElements.map((e) => {
                // TODO - this should probably be a separate render function.
                switch (e.type) {
                  case 'checkbox':
                    return (
                      <Form.Group key={e.name} controlId={e.name}>
                        <Form.Check {...e} value={formik.values[e.name]} onChange={formik.handleChange} />
                      </Form.Group>
                    );
                  default:
                    return (
                      <Form.Group key={e.name} controlId={e.name}>
                        <Form.Label>{e.label}</Form.Label>
                        <Form.Control {...e} placeholder={e.label} value={formik.values[e.name]} onChange={formik.handleChange} />
                      </Form.Group>
                    );
                }
              })}
              <div className="d-flex justify-content-center mt-8">
                <Button variant="gradient-primary" type="submit" disabled={isLoading}>
                  {t.form.submit}
                  {isLoading && <span> ...</span>}
                </Button>
              </div>
            </Form>
            {isSuccess && (
              <ElementAlert
                variant="success"
                title={t.form.confirmation.successTitle}
                description={t.form.confirmation.successDescription}
                className="animate-up mt-6"
              />
            )}
            {isError && (
              <ElementAlert
                variant="danger"
                title={t.form.confirmation.errorTitle}
                description={t.form.confirmation.errorDescription}
                className="animate-up mt-6"
              />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default WebFormContactUs;
