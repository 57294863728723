/* eslint-disable max-len */
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import ElementAlert from 'src/components/ElementAlert/ElementAlert';
import { useForm, useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

function WebFormRequestAnOffer() {
  const t = useTheme();
  const RANGE_MIN = 5000;
  const RANGE_MAX = 200000;
  const RANGE_STEP = 5000;
  const getRangeBgClass = (value, min, max) => `range-track-bg-size-${Math.round(((value - min) / (max - min)) * 100)}`;
  const initialValues = {
    project_type: '',
    services: [],
    budget: RANGE_MIN,
    name: '',
    email: '',
    message: '',
    privacy_policy: false,
    tabs: 'offer_request',
    webform_id: 'contact_us',
  };
  const formElementsProjectTypes = [
    { label: t.form.newProject, name: 'new_project', id: 'bcwejkbvfej2kfv' },
    { label: t.form.existingProject, name: 'existing_project', id: 'nbcoenipdw1bvuoi3r' },
  ];
  const formElements = [
    { name: 'budget', label: t.form.budget, type: 'range', min: RANGE_MIN, max: RANGE_MAX, step: RANGE_STEP, custom: true },
    { name: 'name', label: t.form.name, type: 'text' },
    { name: 'email', label: t.form.email, type: 'email' },
    { name: 'message', label: t.form.message, type: 'text', as: 'textarea', rows: 5 },
  ];
  const { formik, isLoading, isSuccess, isError } = useForm({ initialValues });
  const data = useStaticQuery(graphql`
    {
      allSanityService {
        nodes {
          _id
          title
          previewImageContactForm {
            alt
            image {
              asset {
                gatsbyImageData(height: 100, fit: CROP)
              }
            }
          }
        }
      }
    }
  `);
  const formElementsServices = data.allSanityService.nodes.map((e) => ({
    id: e._id,
    label: e.title,
    image: {
      alt: e.previewImageContactForm?.alt ?? '',
      data: e.previewImageContactForm?.image?.asset?.gatsbyImageData ?? imgFallback,
    },
  }));

  return (
    <Container fluid className="px-0 py-6">
      <Container>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} xl={{ span: 6, offset: 3 }}>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group controlId="services">
                <Form.Label className="visually-hidden">{t.form.services}</Form.Label>
                <Row className="mx-xl-n10">
                  {formElementsServices.map((e) => (
                    <Col key={e.id} xs={6} lg={4} className="py-3">
                      <Form.Group controlId={e.label} className="pl-0">
                        <Form.Check.Input
                          type="checkbox"
                          name="services"
                          value={e.id}
                          checked={formik.values.services.includes(e.id)}
                          onChange={formik.handleChange}
                          className="visually-hidden on-checked-border-orange m-0"
                        />
                        <Form.Check.Label className="w-100 shadow-form-element on-hover-border-secondary cursor-pointer overflow-hidden rounded-xl bg-gray-800 text-white">
                          <Col xs={12} className="p-2">
                            <Row noGutters className="align-items-center">
                              <Col xs={12} sm={6} className="d-flex align-items-center justify-content-center">
                                <GatsbyImage alt={e.image.alt} image={e.image.data} />
                              </Col>
                              <Col xs={12} sm={6} className="position-relative text-sm-left text-size-sm font-weight-bold text-center">
                                {e.label}
                              </Col>
                            </Row>
                          </Col>
                        </Form.Check.Label>
                      </Form.Group>
                    </Col>
                  ))}
                </Row>
              </Form.Group>
              <Form.Group controlId="project_type">
                <Form.Label>{t.form.projectType}</Form.Label>
                <Row className="justify-content-center">
                  {formElementsProjectTypes.map((e) => (
                    <Col key={e.label} xs={6} className="py-3">
                      <Form.Check id={e.label} className="pl-0">
                        <Form.Check.Input
                          type="radio"
                          name="project_type"
                          label={e.label}
                          value={e.name}
                          checked={formik.values.project_type.includes(e.name)}
                          onChange={formik.handleChange}
                          className="visually-hidden on-checked-border-orange m-0"
                        />
                        <Form.Check.Label className="w-100 shadow-form-element on-hover-border-secondary cursor-pointer overflow-hidden rounded-xl bg-gray-800 py-2 text-white">
                          <Col xs={12} className="py-sm-5 text-size-sm font-weight-bold d-flex justify-content-center py-3">
                            {e.label}
                          </Col>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  ))}
                </Row>
              </Form.Group>
              {formElements.map((e) => (
                <Form.Group key={e.label} controlId={e.name}>
                  <Form.Label>{e.label}</Form.Label>
                  {e.type === 'range' && (
                    <Row noGutters className="justify-content-between my-1 flex-nowrap">
                      <Col xs="auto text-white text-size-2xs font-weight-bold d-flex align-items-end">{`${e.min} ${t.form.priceSuffix}`}</Col>
                      <Col>
                        <output htmlFor={e.name} className="text-secondary d-block text-size-xs font-weight-bold text-center">
                          {`${formik.values[e.name]}${formik.values[e.name] === `${RANGE_MAX}` ? '+' : ''} ${t.form.priceSuffix}`}
                        </output>
                      </Col>
                      <Col xs="auto text-white text-size-2xs font-weight-bold d-flex align-items-end">{`${e.max}+ ${t.form.priceSuffix}`}</Col>
                    </Row>
                  )}
                  <Form.Control
                    {...e}
                    required
                    placeholder={e.label}
                    value={formik.values[e.name]}
                    onChange={formik.handleChange}
                    className={e.type === 'range' && getRangeBgClass(formik.values.budget, RANGE_MIN, RANGE_MAX)}
                  />
                </Form.Group>
              ))}
              <Form.Group controlId="bclewbvjlr32bvjlr">
                <Form.Check
                  custom
                  required
                  type="checkbox"
                  name="privacy_policy"
                  label={
                    <span className="text-size-sm text-white">
                      <span className="mr-1">{t.form.gdprConsent}</span>
                      <Link to={`/${t.navigation.gdpr.path}`} className="text-secondary font-weight-semi-bold">
                        {t.navigation.gdpr.label}
                      </Link>
                    </span>
                  }
                  checked={formik.values.privacy_policy}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <div className="d-flex justify-content-center mt-8">
                <Button variant="gradient-primary" type="submit" disabled={isLoading}>
                  {t.form.submit}
                  {isLoading && <span> ...</span>}
                </Button>
              </div>
            </Form>
            {isSuccess && (
              <ElementAlert
                variant="success"
                title={t.form.confirmation.successTitle}
                description={t.form.confirmation.successDescription}
                className="animate-up mt-6"
              />
            )}
            {isError && (
              <ElementAlert
                variant="danger"
                title={t.form.confirmation.errorTitle}
                description={t.form.confirmation.errorDescription}
                className="animate-up mt-6"
              />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default WebFormRequestAnOffer;
