import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
// import BlockContentContact from 'src/components/BlockContentContact/BlockContentContact';
import { Hero } from 'src/components/LandingPage';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import WebFormContactUs from 'src/components/WebFormContactUs/WebFormContactUs';
import WebFormContext from 'src/components/WebFormContext/WebFormContext';
import WebFormRequestAnOffer from 'src/components/WebFormRequestAnOffer/WebFormRequestAnOffer';
import WebFormWrapper from 'src/components/WebFormWrapper/WebFormWrapper';
import { useTheme } from 'src/hooks';

function ContactPage({ data, location }) {
  const { form } = useTheme();

  const {
    sanityBasicPage: {
      contact: { hero },
      title,
      pageSeo,
    },
  } = data;

  const seo = {
    title: pageSeo?.title ?? title,
    description: pageSeo?.description,
    image: pageSeo?.image?.asset?.url,
    location,
  };

  const webForms = [
    { component: <WebFormContactUs />, tab: form.curious, id: 'ncdiobejdlbjfe3' },
    { component: <WebFormRequestAnOffer />, tab: form.needAnOffer, id: 'bekbi2vueibve3' },
  ];

  return (
    <PageLayout>
      <WebFormContext>
        <PageSEO {...seo} />
        <Container fluid className="bg-shapes-banner px-0">
          <Hero hero={hero} />
        </Container>
        {/* <BlockContentContact />  */}
        <div id="contact-section">
          <WebFormWrapper data={webForms} tagline={form.tagline} title={form.title} />
        </div>
      </WebFormContext>
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    sanityBasicPage(id: { eq: $id }) {
      title
      pageSeo {
        ...getPageSeo
      }
      contact {
        hero {
          ...getHero
        }
      }
    }
  }
`;

ContactPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
export default ContactPage;
